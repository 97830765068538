import {
  gaComponentConstants,
  gaEventConstants,
} from '../constants/GAConstants';

import { Button } from 'antd';
import { useAnalyticsEventTracker } from '../utils/GAUtils';

interface ButtonComponentProps {
  findQueryParamsFromUrl: () => void;
}

function ButtonComponent({ findQueryParamsFromUrl }: ButtonComponentProps) {
  const gaEventTracker = useAnalyticsEventTracker(gaComponentConstants.BUTTON);

  return (
    <div style={{ display: 'flex' }}>
      <Button
        style={{
          width: '100px',
          background: '#989fce',
          fontStyle: 'unset',
          fontWeight: 'bold',
          color: 'black',
          marginLeft: 'auto',
          marginTop: '-10px',
        }}
        htmlType='submit'
        type='primary'
        onClick={() => {
          findQueryParamsFromUrl();
          gaEventTracker(gaEventConstants.FORMAT_BUTTON);
        }}
      >
        FORMAT
      </Button>
    </div>
  );
}

export default ButtonComponent;
