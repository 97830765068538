import { Button, Input, Table, Tooltip, message } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { SearchOutlined, ShareAltOutlined } from '@ant-design/icons';
import {
  gaComponentConstants,
  gaEventConstants,
} from '../constants/GAConstants';

import DuplicateIcon from '../assets/DuplicateIcon.svg';
import { useAnalyticsEventTracker } from '../utils/GAUtils';
import { useState } from 'react';

export interface DataType {
  Key: string;
  Value: string;
}
interface TextInputProps {
  data: DataType[];
  encodedValue: string;
}

type DataIndex = keyof DataType;

function TableComponent({ data, encodedValue }: TextInputProps) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedParams, setSelectedParams] = useState<any>(data);
  const [messageApi, contextHolder] = message.useMessage();
  const gaEventTracker = useAnalyticsEventTracker(gaComponentConstants.TABLE);

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Link copied',
    });
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
          width: '180px',
          rowGap: '5px',
        }}
      >
        <Input
          width={'10px'}
          autoFocus
          placeholder='Enter text here'
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedParams(e.target.value ? [e.target.value] : []);
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({
              closeDropdown: false,
            });
          }}
          onPressEnter={() => {
            setSelectedKeys(selectedParams);
            confirm({
              closeDropdown: true,
            });
            gaEventTracker(gaEventConstants.SEARCH);
          }}
        ></Input>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            columnGap: '10px',
            paddingTop: 'inherit',
          }}
        >
          <Button
            onClick={() => {
              setSelectedKeys(selectedParams);
              confirm({ closeDropdown: true });
              gaEventTracker(gaEventConstants.SEARCH);
            }}
            type='primary'
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm({
                closeDropdown: true,
              });
              setSelectedParams(data);
              gaEventTracker(gaEventConstants.RESET);
            }}
            type='primary'
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),

    filterIcon: () => {
      return <SearchOutlined />;
    },
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'Key',
      dataIndex: 'Key',
      key: 'Key',
      width: '30%',
      ...getColumnSearchProps('Key'),
      onFilter: (value: any, record: any) => {
        return record.Key.toLowerCase().includes(value.toLowerCase());
      },
      render: (record: any, index: any) =>
        record && index.Value.includes(',') ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              overflowWrap: 'anywhere',
              alignItems: 'center',
              columnGap: '10px',
            }}
          >
            {' '}
            {record}
            <Tooltip placement='topLeft' title={'Duplicated Key'}>
              <img src={DuplicateIcon} height='30px' />
            </Tooltip>
          </div>
        ) : (
          <> {record}</>
        ),
    },
    {
      title: 'Value',
      dataIndex: 'Value',
      key: 'Value',
      width: '30%',
      ...getColumnSearchProps('Value'),
      onFilter: (value: any, record: any) => {
        return record.Value.toLowerCase().includes(value.toLowerCase());
      },
      render: (record: any) => {
        const duplicatedValues = (record as string).split(',');
        return record && duplicatedValues.length > 1 ? (
          <>
            <ul
              style={{
                listStyleType: 'disc',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                margin: '0px',
                translate: '-25px 0px',
              }}
            >
              {duplicatedValues.map((value, index) => {
                return (
                  <li style={{ paddingRight: 30 }} key={index}>
                    {value}
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <>{record}</>
        );
      },
    },
  ];

  return data.length > 0 ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className='sub-text-style'>
        {data.length > 1
          ? data.length + ' Query Params '
          : data.length + ' Query Param '}
        {contextHolder}
        <Button
          style={{
            width: '100px',
            background: '#989fce',
            fontStyle: 'unset',
            fontWeight: 'bold',
            color: 'black',
            marginLeft: 'auto',
          }}
          type='primary'
          onClick={() => {
            navigator.clipboard.writeText(encodedValue);
            success();
            gaEventTracker(gaEventConstants.RESULT_COPIED);
          }}
        >
          <ShareAltOutlined />
          SHARE
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        size='middle'
        pagination={{
          current: page,
          pageSize: pageSize,
          total: selectedParams.length,
          onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
            gaEventTracker(gaEventConstants.PAGINATION);
          },
          pageSizeOptions: ['10', '25', '50'],
          showSizeChanger: true,
        }}
        scroll={{ y: 540 }}
      />
    </div>
  ) : null;
}

export default TableComponent;
