import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Home from './components/Home';
import React from 'react';
import ReactGA from 'react-ga4';

if (process.env.REACT_APP_TRACK_GA === 'TRUE') {
  ReactGA.initialize(process.env.REACT_APP_GA_STREAM_ID as string);
}

const App: React.FC = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path={'/*'} element={<Home />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
