import React, { useCallback, useEffect } from 'react';
import TableComponent, { DataType } from './TableComponent';

import ButtonComponent from './ButtonComponent';
import { Layout } from 'antd';
import MyHeader from './MyHeader';
import TextInput from './TextInput';

const { Content } = Layout;

const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#dfd9e2',
  padding: '0px 50px',
  paddingTop: '50px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '40px',
};

function Home() {
  const [urlInput, setUrlInput] = React.useState('');
  const [data, setData] = React.useState<DataType[]>([]);
  const encodedPathName = `${window.location.origin + `/${btoa(urlInput)}`}`;

  const findQueryParamsFromUrl = useCallback(() => {
    const queryParams: { [key: string]: string[] } = {};

    const regex = /[?&]([^=#]+)=([^&#]*)/g;
    let match;

    while ((match = regex.exec(urlInput))) {
      const key = decodeURIComponent(match[1]);
      const value = decodeURIComponent(match[2]);

      if (queryParams.hasOwnProperty(key)) {
        queryParams[key].push(value);
      } else {
        queryParams[key] = [value];
      }
    }

    setData(
      Object.keys(queryParams).map((queryParamKey: string) => {
        return {
          Key: queryParamKey,
          Value: queryParams[queryParamKey].join(','),
        };
      })
    );
  }, [urlInput]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      findQueryParamsFromUrl();
    }
  };

  useEffect(() => {
    if (window.location.pathname !== '/') {
      setUrlInput(atob(window.location.pathname.slice(1)));
      findQueryParamsFromUrl();
    } else setUrlInput(urlInput);
  }, [urlInput, findQueryParamsFromUrl]);

  return (
    <div>
      <Layout
        style={{
          minHeight: '100vh',
          justifyContent: 'center',
        }}
      >
        <MyHeader />
        <Content style={contentStyle}>
          <TextInput
            urlInput={urlInput}
            setUrlInput={setUrlInput}
            onKeyDown={handleKeyDown}
          />
          <ButtonComponent findQueryParamsFromUrl={findQueryParamsFromUrl} />
          <TableComponent data={data} encodedValue={encodedPathName} />
        </Content>
      </Layout>
    </div>
  );
}

export default Home;
