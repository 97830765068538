import ReactGA from 'react-ga4';

export const useAnalyticsEventTracker = (category: string) => {
  if (process.env.REACT_APP_TRACK_GA === 'TRUE') {
    const eventTracker = (action: string, label?: string) => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  } else {
    return () => {
      console.log('GA not tracked');
    };
  }
};
