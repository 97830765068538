import { Col, Row } from 'antd';

function MyHeader() {
  return (
    <div className='header-style'>
      <Row>
        <Col xs={24} sm={12} md={8} lg={8} className='header-text'>
          URL FORMATTER
        </Col>
        <Col xs={24} sm={12} md={16} lg={16} className='header-sub-text'>
          A basic formatter for breaking up a one line URL into parameters
        </Col>
      </Row>
    </div>
  );
}

export default MyHeader;
