import { Input } from 'antd';
import React from 'react';

interface TextInputProps {
  urlInput: string;
  setUrlInput: React.Dispatch<React.SetStateAction<string>>;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

function TextInput({ urlInput, setUrlInput, onKeyDown }: TextInputProps) {
  return (
    <>
      <div className='text-style'>
        Paste the url in the text box below, select "FORMAT" button and see the
        result.
      </div>
      <Input
        style={{ height: '50px', minHeight: '50px' }}
        placeholder='Enter url'
        onChange={(e) => {
          setUrlInput(e.target.value);
        }}
        onKeyDown={onKeyDown}
        value={urlInput}
      />
    </>
  );
}

export default TextInput;
