export const gaEventConstants = {
  FORMAT_BUTTON: 'Url formatted',
  SEARCH: 'Searching for entered key/value',
  RESET: 'Filter reset',
  RESULT_COPIED: 'Url copied',
  PAGINATION: 'Result page changed',
};

export const gaComponentConstants = {
  TABLE: 'Table component used',
  BUTTON: 'Button component used',
};
